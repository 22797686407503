import React from 'react';
import JotformEmbed from 'react-jotform-embed';
import autoprefixer from 'autoprefixer';
import SEO from '../../components/SEO';

import Layout from '../../layouts/contactLayout';
import Call from '../../components/Call';

const Contact = (props) => (
  <Layout bodyClass="page-contact contact-page">
    <SEO
      title="Contact LaSalle For Heating, Furnace, AC or Ductwork Intallation, Repair, or Service"
      description="Schedule an appointment 24/7 for Heating and Cooling service, repair, or installation"
    />
    <div className="container22">
      <div
        className="container22 pt-4 pb-4"
        style={{ margin: '0 auto', textAlign: 'center' }}
      >
        <section className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
          <div className="text-center">
            <p className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              Application for Employment
            </p>
            <p className="text-xl tracking-tight font-bold text-gray-400">
              (Equal Employment Opportunity Employer)
            </p>
          </div>
        </section>
      </div>
      <div className="employment-form">
        <JotformEmbed src="https://form.jotformeu.com/203036832576052" />
      </div>
    </div>
  </Layout>
);

export default Contact;
